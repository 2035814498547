export const DATA = [
  {
    id: 9,
    title: 'Welcome to\n Enter ECI Awards 2023',
    dTitle: 'Welcome to Enter ECI Awards 2023',
    img: [],
    titleimg: '',
    desc: ``,
  },
  {
    id: 1,
    title: 'Competition Calendar',
    dTitle: 'Competition Calendar',
    img: [],
    titleimg: '',
    desc: ``,
  },
  {
    id: 2,

    title: 'Eligibility',
    dTitle: 'Eligibility',
    img: [],
    titleimg: '',
    desc: ``,
  },
  {
    id: 3,

    title: 'Categories',
    dTitle: 'ECI Awards 2023 Entry Category Definitions',
    img: [],
    titleimg: '',
    desc: ``,
  },
  {
    id: 4,

    title: 'Submit Your Entry',
    dTitle: 'Submit Your Entry',
    img: [],
    titleimg: '',
    desc: ``,
  },
  {
    id: 5,

    title: 'Distribution of Awards',
    dTitle: 'Distribution of Awards',
    img: [],
    titleimg: '',
    desc: ``,
  },
  {
    id: 6,
    title: 'Judging Criteria',
    dTitle: 'Judging Criteria',
    img: [],
    titleimg: '',
    desc: ``,
  },

  {
    id: 7,

    title: 'Nominate a Judge',
    dTitle: 'Nominate a Judge',
    img: [],
    titleimg: '',
    desc: ``,
  },
  {
    id: 8,

    title: 'ECI Innovation Index',
    dTitle: 'ECI Innovation Index',
    img: [],
    titleimg: '',
    desc: ``,
  },
];
