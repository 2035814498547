<template>
  <div class="entry_box main">
    <div class="container content">
      <div class="title_box">
        <div class="title">
          Everything you need to know about entering the ECI Awards.
        </div>
      </div>
      <div class="enrty_list">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          @click="toDetail(item.id)"
        >
          <img
            class="img"
            :src="require('@/assets/images/a' + (index + 1) + '.png')"
          />
          <div class="name">
            {{ item.title }}
          </div>
          <img class="arrow" src="@/assets/images/arrow.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DATA } from '@/data/a_entry.js';
export default {
  name: 'Entry',
  components: {},
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },

  methods: {
    getList() {
      this.list = DATA;
    },
    toDetail(id) {
      this.$router.push({
        name: 'AwardsEntryDetail',
        query: { id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title_box {
  .title {
    font-size: 24px;
  }
}
.enrty_list {
  margin-top: 50px;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .item:hover {
    background-color: #2b8bc3;
  }
  .item {
    background-color: #e9edf2;
    cursor: pointer;
    flex: 0 0 32%;
    // min-height: 200px;
    line-height: 25px;
    position: relative;
    margin-right: calc(3% / 3);
    margin-bottom: calc(3% / 3);
  }

  .item:nth-child(3n) {
    margin-right: 0;
  }

  // .item:last-child {
  //   margin-right: auto;
  // }
  .img {
    display: block;
    width: 100%;
  }
  .name {
    white-space: pre-wrap;
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    top: 0;
    padding: 10px;
  }
  .arrow {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
@media (max-width: 950px) {
  .enrty_list {
    margin-top: 50px;
    display: flex;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item:hover {
      background-color: #dce0e7;
    }
    .item {
      flex: 0 0 45%;

      margin-right: calc(10% / 2);
      margin-bottom: calc(10% / 2);
    }
    .item:nth-child(2n) {
      margin-right: 0;
    }
    .name {
      font-size: 18px;

      line-height: 24px;
    }
    .arrow {
      right: 10px;
      top: 10px;
      width: 10px;
    }
  }
}
</style>
